@import '../../../sass/settings';

.wrap {
    position: fixed;
    top: 56px;
    left: 0;
    z-index: 99999999;
    height: 4px;
    width: 100%;
    &.cir {
        position: fixed;
        background-color: rgba($main, .75);
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        @include flex(row, center, center); } }
