@import '../../sass/settings';

.wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
    height: 85%;
    max-width: 1100px;
    @include flex(column, flex-start, center);
    margin: 0 auto;
    h2 {
        color: #878B91;
        font-size: 60px;
        font-weight: 400;
        width: 100%;
        text-align: left; }
    .box {
        width: 100%;
        height: 100%;
        @include shadow();
        @include flex(row, center, center);
        cursor: pointer; } }
