@import '../../../sass/settings';

.btn {
    position: relative;
    // width: 100%
    border: none;
    background-color: $main;
    border: 2px solid $accent;
    border-radius: 4px;
    color: #fff;
    padding: 10px 20px;
    // box-shadow: 0px 0px 10px 0px rgba(#000, .25)
    transition: box-shadow .5s ease;
    letter-spacing: .25px;
    &, & > * {
        cursor: pointer; }
    &.simple {
        text-transform: uppercase;
        font-weight: 400;
        color: $accent;
        border: none;
        letter-spacing: 1px; }
    &.filled {
        color: $main;
        background-color: $accent;
        text-transform: uppercase;
        font-weight: 600;
        text-align: right;
        border-radius: 0px;
        @include shadow();
        letter-spacing: 1px;
        color: #000;
        font-weight: 400;
        padding-right: 0;
        border: none;
        &:disabled {
            background-color: #757575;
            border-color: #757575;
            color: #cecece; } }
    &.small {
        font-size: 14px;
        height: 40px; }
    &.medium {
        font-size: 16px;
        height: 50px; } }
.loading {
    color: transparent !important; }
.loadingIndicator {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-40%); }
