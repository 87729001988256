@import './settings';

/** Disabled outlined input **/

.Mui-disabled {
    opacity: 0.3; }
.MuiOutlinedInput-root {
    fieldset {
        border-color: #757575 !important;
        outline-color: #757575 !important; }
    input {
        color: #cecece !important; } }

.MuiInputBase-root {
    color: rgba(#cecece, 1) !important; }

.MuiInputLabel-formControl {
    color: #cecece !important; }

.MuiInput-underline {
    &::after {
        border-bottom-color: $accent !important; } }

.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    color: #fff; }

.MuiFormHelperText-root.MuiFormHelperText-contained.Mui-disabled.MuiFormHelperText-filled.MuiFormHelperText-marginDense, .MuiFormHelperText-root.MuiFormHelperText-contained.Mui-disabled.MuiFormHelperText-marginDense {
    color: $accent;
    opacity: 1; }

/** Date popup **/
.MuiPaper-root.MuiDialog-paper.MuiPickersModal-dialogRoot.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
    background-color: unset;
    .MuiPickersBasePicker-pickerView {
        background-color: #212121;
        .MuiPickersCalendarHeader-switchHeader {
            button {
                background-color: #212121;
                svg {
                    color: $accent; } } }
        .MuiTypography-root.MuiTypography-body1.MuiTypography-alignCenter {
            color: #fff; }
        .MuiPickersCalendarHeader-daysHeader {
            span {
                color: #cecece; } }
        .MuiButtonBase-root.MuiIconButton-root.MuiPickersDay-day {
            color: #fff;
            &.MuiPickersDay-daySelected {
                color: #212121; } } }
    .MuiDialogActions-root.MuiDialogActions-spacing {
        background-color: #212121; } }

.MuiPaper-root.MuiStepper-root {
    background-color: unset; }

.MuiFormControl-root {
    .MuiFormLabel-root {
        transform: translate(15px, 12px); }
    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
        .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input {
            padding: calc(#{$gutter / 2} + .5px); }
        .MuiSelect-nativeInput {
                transform: translateY(-50%); }
        .MuiSelect-icon {
                color: #fff; } } }

.infinite-scroll-component .MuiAutocomplete-inputRoot {
    flex-wrap: nowrap !important; }

.MuiAlert-outlinedInfo {
    border: 1px solid $MuiAlert_main_color !important;
    svg {
        fill: $MuiAlert_main_color !important; }
    .MuiAlert-message {
        font-family: 'Montserrat';
        font-weight: 400;
        color: $MuiAlert_main_color !important; } }

.MuiChip-root.success {
    background-color: $success;
    color: #fff; }

.MuiChip-root.danger {
    background-color: $error;
    color: #fff; }

.MuiChip-root.primary {
    background-color: $accent;
    color: #fff; }
