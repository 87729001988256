@import '../../sass/settings';

.header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    height: $header_height;
    transition: width .35s ease;
    background: transparent;
    @include flex(row, flex-start, center);
    .logo {
        width: 60px;
        height: 100%;
        cursor: pointer;
        img {
            margin: 5px 0px; } }
    .leftTools, .rightTools {
        @include flex(row, center, center);
        button {
            margin: 0 5px; } }
    .rightTools {
        .active {
            fill: $accent; } }
    .welcomeMsg {
        margin-left: auto;
        margin-right: $gutter;
        color: $text; }
    .userProfile {
        @include flex(row , center, center);
        // margin-left: auto
        .userAvatar {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background-position: center;
            background-size: cover;
            margin-right: $gutter/2;
            margin-left: $gutter/2;
            position: relative;
            cursor: pointer;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                border-radius: 50%; } } }

    .SideBarBurger {
        margin-left: 10px;
        padding: 8px;
        border-radius: 8px;
        border: 2px solid transparent;
        .SideBarBurgerImage {
            width: 40px;
            height: 25px;
            background:  url("../../assets/img/menu/menu-toggle-icon.png") no-repeat; } }
    .SideBarBurgerOpen {
        border: 2px solid $accent;
        .SideBarBurgerImage {
            background: url("../../assets/img/menu/menu-toggle-icon.png") no-repeat; } }
    .HeaderLogo {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: 45px;
        margin-right: 20px;
        img {
            width: 140px;
            height: 62px; }
        @media screen and ( max-width: 600px ) {
            margin-left: 20px; } }

    .headerSearchWrapper {
        max-width: 695px;
        width: 100%;
        display: flex;
        margin-left: auto;
        align-items: center;
        gap: 10px; }
    .HeaderSearchBlock {
        max-width: 590px;
        width: 100%;
        height: 80px;
        background: #1D1D1D;
        border-radius: 10px;
        display: flex;
        align-items: center;
        padding-left: 20px;
        margin-left: auto;
        margin-right: 10px;
        @media screen and ( max-width: 1023px ) {
            height: 64px; } }
    .HeaderSearchInputBlock {
        position: relative;
        width: 100%;
        height: 48px;
        @media screen and ( max-width: 1023px ) {
            height: 38px; }
        input {
            width: calc(100%);
            height: 48px;
            background: #232323;
            border-radius: 24px;
            padding: 0 24px 0 34px;
            border: none;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 130%;
            color: #666666;
            @media screen and ( max-width: 1023px ) {
                height: 38px; } } }
    .HeaderSearchIcon {
        position: absolute;
        top: 50%;
        left: 10px;
        width: 20px;
        height: 20px;
        transform: translateY(-50%);
        background: url("../../assets/img/menu/search-icon.png") no-repeat; } }

/** SideBar **/
.sideBar {
    position: fixed !important;
    top: $header_height;
    left: 0;
    width: $sidebar_width;
    height: calc(100% - #{$main_block_top_padding});
    transform: translateX(-100%);
    transition: transform .35s ease;
    padding: 25px !important;
    padding-right: 10px !important;
    background: #1d1d1d;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    z-index: 99;

    &.open {
        transform: translateX(0%); }
    .DesktopMenuScrollbars {
        overflow-y: scroll;
        padding-bottom: 20px;
        -webkit-overflow-scrolling: touch;
        scrollbar-color: transparent transparent !important;
        scrollbar-width: none;

        /* For WebKit (Safari, Chrome, etc.) */
        &::-webkit-scrollbar {
            width: 0;
            height: 0; } }
    .MenuScrollbars {
        > div:first-child {
            padding-right: 15px; } }
    .userBlock {
        display: flex;
        min-height: 100px; }
    .userAvatar {
        border-radius: 50%;
        margin-right: 42px; }
    .userAvatarImage {
        display: block;
        width: 70px;
        height: 70px;
        background: #fff;
        border-radius: 50%;
        background-position: center;
        background-size: cover;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain; } }
    .userEditButton {
        cursor: pointer;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: absolute;
        top: 45px;
        left: 80px;
        background: #fff url("../../assets/img/menu/user_edit.png") no-repeat;
        background-position: center; }

    .userName {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 170%;
        color: #FFFFFF; }
    .userRolesText {
        font-size: 12px;
        line-height: 16px;
        background-color: $accent;
        float: left;
        padding: 0 10px;
        border-radius: 8px; }
    .itemIconBlock {
        display: block;
        width: 60px; }
    .itemIconImage {
        display: block;
        width: 22px;
        height: 22px;
        margin: 0 auto;
        background-repeat: no-repeat; }
    .item {
        @include flex(row, flex-start, center);
        max-width: 315px;
        min-height: 64px;
        margin: $gutter/2 0;
        padding: 0;
        border: 1px solid transparent;

        background: #1D1D1D;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
        border-radius: 10px;

        //right: 15px
        a {
            width: 100%;
            // height: 100%
            @include flex(row, flex-start, center);
            padding-right: $gutter /2;
            height: 64px;
            text-align: left; }
        &.withSub {
            margin-bottom: 0 !important;
            padding-right: $gutter / 2;
            svg {
                position: absolute;
                right: 0; } }
        &.active {
            box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.2);
            background-color: $accent;
            background-repeat: no-repeat;
            p {
                z-index: 999; }
            svg {
                position: absolute;
                g {
                    path {
                        fill: rgba($accent_r, $accent_g10, $accent_b, 1); } } } }

        &:last-of-type {
            margin-bottom: $gutter * 4; }
        .icon {
            color: $accent;
            height: 20px;
            position: absolute;
            right: 8px;
            top: 10px;
            transform: translate(50%, -50%); } }
    .subMenu {
        background-color: rgba($accent_r, $accent_g, $accent_b, .1);
        border-radius: 6px;
        transition: all .25s ease;
        max-width: 315px;
        > li {
            padding: 0;
            &:hover {
                background-color: rgba($accent_r, $accent_g, $accent_b, .2); }
            a {
                display: flex;
                align-items: center;
                width: 100%;
                text-align: left;
                // +flex(row, flex-end, center)
                padding: 5px;
                padding-right: $gutter;
                padding-top: 8px;
                padding-bottom: 8px;
                div {
                    display: block;
                    width: 10px;
                    height: 10px;
                    background-color: rgba($accent_r, $accent_g, $accent_b, .5);
                    margin-left: 15px;
                    margin-right: 15px;
                    border-radius: 50%; } }
            &.active {
                div {
                    background-color: $accent; } } }
        .subItem {
            background-color: rgba($accent_r, $accent_g, $accent_b, .1); } } }

.exchangeViewSwitchButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 75px;
    height: 75px;
    background: rgba(42, 42, 42, 1);
    border-radius: 10px;
    position: relative;
    margin-right: 10px;
    cursor: pointer;
    transition: background 0.2s ease-in-out;

    &:hover {
        background: #343434;

        &:before {
            background: rgba(42, 42, 42, 1); } }

    svg {
        color: rgba(24, 24, 24, 1);
        width: 40px !important;
        height: 40px !important; }
    &:before {
        content: '';
        width: 81px;
        height: 81px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: linear-gradient(180deg, #343434 0%, rgba(42, 42, 42, 1) 65%);
        border-radius: inherit;
        z-index: -1; }

    .exchangeViewSwitchButtonTooltip {
        position: absolute;
        bottom: 0;
        left: calc(100% - 2px);
        font-size: 8px;
        background-color: white;
        color: rgba(24, 24, 24, 1) !important;
        padding: 7px;
        z-index: 100;
        text-wrap: nowrap;
        border-radius: 3px;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 600; }

    @media screen and ( max-width: 1023px ) {
        width: 60px;
        height: 60px;

        &:before {
            width: 64px;
            height: 64px; }

        svg {
            width: 30px !important;
            height: 30px !important; } }

    @media screen and ( max-width: 640px ) {
        width: 52px;
        height: 52px;

        &:before {
            width: 57px;
            height: 57px; }

        svg {
            width: 25px !important;
            height: 25px !important; } }

    @media screen and ( max-width: 480px ) {
        margin-right: 0;
        justify-self: flex-end; } }
