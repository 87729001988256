@import '../../sass/settings';

.content {
    @include flex(row, center, center);
    margin-top: $header_height;
    width: 100%;
    .main {
        @include flex(row, center, center);
        width: 100%;
        height: 100%;
        .block {
            @include flex(column, flex-start, center);
            width: 400px;
            height: 850px;
            background: linear-gradient(180deg, #252525 0%, #1F1F1F 100%);
            box-shadow: 0px 20px 14px rgba(0, 0, 0, 0.15);
            border-radius: 12px;
            margin-bottom: 120px;
            .logo {
                width: 160px;
                height: 160px;
                margin-top: -110px;
                margin-right: 20px;
                background: url("../../assets/img/login-logo.png") no-repeat;
                filter: drop-shadow(0px 30px 10px rgba(0, 0, 0, 0.15)); }
            .loginInfo {
                @include flex(column, center, center);
                .signIn {
                    font-weight: 600;
                    font-size: 22px;
                    line-height: 185%;
                    margin: 20px 0px; }
                .logIn {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 170%;
                    margin-top: 15px;
                    margin-bottom: 10px; }
                .info {
                    @include flex(column, flex-start, center);
                    width: 340px;
                    height: 290px;
                    background: #1F1F1F;
                    border-radius: 10px;
                    padding: 25px;
                    .infoWallet {
                        margin-top: 10px;
                        font-size: 16px;
                        line-height: 130%;
                        text-align: center;
                        color: rgba(255, 255, 255, 0.3); }
                    img {
                        margin-top: 25px; } }
                .loginForm {
                    @include flex(column, space-evenly, normal);
                    height: 280px;
                    fieldset {
                        border-color: rgba(255, 255, 255, 0.3);
                        border-radius: 12px; }
                    .loginBtn {
                        width: 340px;
                        height: 60px;
                        background: $accent;
                        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.2);
                        border-radius: 10px;
                        span {
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 130%;
                            text-align: center;
                            text-transform: uppercase;
                            color: #FFFFFF; } } }
                .links {
                    @include flex(column, center, normal);
                    width: 100%;
                    margin-bottom: 10px;
                    p {
                        margin: 5px 0px;
                        color: rgba(255, 255, 255, 0.3); }
                    a {
                        color: #FFFFFF !important; } } } } } }
