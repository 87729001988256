/* CSS Reset */
* {
  margin: 0;
  padding: 0;
  outline: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-family: 'Roboto', sans-serif;
}

ul li {
  list-style-type: none;
}

a {
  color: inherit;
  text-decoration: none;
}
a:visited {
  color: inherit;
}

button {
  border: none;
  font: unset;
}

::placeholder html {
  scroll-behavior: smooth;
}
