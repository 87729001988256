@import './settings';
@import './resets';
@import './mui';
@import './rechtext.module';

@font-face {
    font-family: 'BrownPro';
    src: local('BrownPro'), url(../assets/fonts/BrownPro-Bold.otf);
    font-weight: bold; }

@font-face {
    font-family: 'BrownPro';
    src: local('BrownPro'), url(../assets/fonts/BrownPro-Regular.otf); }

body {
    @include flex(column, flex-start, flex-start);
    font-size: 12pt;
    background-color: $main_background;
    font-family: 'Roboto', sans-serif;
    width: 100%;
    height: 100%;
    color: #fff;
    overflow: hidden; }

::-webkit-calendar-picker-indicator {
    filter: invert(1); }

.Mui-checked svg {
    color: $accent !important; }

#root {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.7) #5e5e5e;
    width: 100%;
    height: 100vh;
    transition: filter .15s ease;
    &.openDialog {
        // filter: blur(3px) grayscale(100%)
 } }        // background-color: rgba(#fff, .25)

*, ::after, ::before {
    scrollbar-width: inherit;
    scrollbar-color: inherit; }

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #5e5e5e; }

::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.7);
    border-radius: 0.5em; }

#app-wrap {
    width: 100%;
    height: 100%;
    @include flex(row, flex-end, flex-start);
    position: relative;
    overflow-x: hidden;
    .breadcrumbs {
        align-self: flex-start;
        margin-left: $gutter *1.5;
        margin-top: $gutter;
        background-color: $accent;
        padding: $gutter/2 $gutter;
        width: max-content;
        border-radius: 6px;
        ol {
            flex-wrap: nowrap; }
        @include shadow();
        .activeCrumb {
            color: $main; } } }

main {
    position: relative;
    padding: $main_block_top_padding 40px 0 0;
    width: 100%;
    height: 100%;
    @include flex(column, flex-start, flex-start);
    transition: width .35s ease;
    @media screen and ( max-width: 800px ) {
        padding: $main_block_top_padding 0 0 0; }
    // color: #fff
    &.withSidebar {
        width: calc(100% - #{$sidebar_width}); } }

.welcome {
    color: #cecece;
    font-size: 200%;
    width: 100%;
    text-align: center; }

.auth-close,
.separator,
.gFSwGh .separator,
.signup {
    display: none !important; }

// Remove outline in chrome on click
button {
    &:focus {
        outline: 0; } }

.se-image-container {
    max-width: 100%; }

.customErrorMsg {
    margin-left: 14px;
    margin-right: 14px;
    margin-top: 4px;
    font-size: 0.75rem;
    font-family: Roboto, "Trebuchet MS", PFFuturaNeuBook, "Open Sans","Helvetica Neue",Arial,sans-serif;
    font-weight: 400;
    line-height: 1.66;
    color: #f44336 !important; }

#ReactSimpleImageViewer {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }

.rug-image-drop-icon-wrapper {
    position: relative;
    width: 180px;
    height: 180px;
    transition: width 0.3s ease-out, height 0.3s ease-in-out;
    .rug-image-drop-circle-icon {
        width: 100%;
        height: 100%; }
    .rug-image-drop-plus-icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); } }

.rug {
    .rug-items.__card {
        min-height: auto !important; }
    .rug-handle {
        @media ( max-width: 960px ) {
            height: 290px !important;
            svg {
                width: 100% !important; } }
        &:before {
            border: 0 !important; }

        &.__dragging {
            &:before {
                border: 1px dashed #fafafa !important;
                background: rgba(255, 255, 255, 0.1) !important; } } } }



.rug-handle {
    padding-top: 19px;
    padding-bottom: 29px;
    cursor: pointer;
    &:hover {
        .rug-image-drop-icon-wrapper {
            width: 190px;
            height: 190px; } }
    .rug-handle-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        .rug-handle-drop-text, span {
            color: #fff !important; }
        .rug-handle-button {
            background-color: unset !important; } } }

.rug-card-progress-count {
    opacity: 0; }

.rug-dragging-item {
    z-index: 5000; }

.dateDialog {
    .MuiPaper-root {
        background-color: #212121;
        color: #fff; }
    .MuiDialogActions-spacing {
        button {
            &:hover {
                color: $accent; } } }
    .MuiPickersCalendarHeader-daysHeader {
        span {
            color: #cecece; } }
    .MuiPickersSlideTransition-transitionContainer.MuiPickersCalendar-transitionContainer {
        color: #fff !important; }
    .MuiButtonBase-root.MuiIconButton-root.MuiPickersDay-day {
        color: #fff !important;
        &.MuiPickersDay-current {
            color: #212121 !important; }
        &.MuiPickersDay-daySelected {
            color: #212121 !important; } }
    .MuiPickersClockNumber-clockNumber {
        color: #fff;
        &.MuiPickersClockNumber-clockNumberSelected {
            color: #212121; } }
    .MuiButtonBase-root.MuiIconButton-root.MuiPickersCalendarHeader-iconButton {
        background-color: unset; } }
.text-overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }


#app-wrap {
    .appHeader {
        transition: all .15s, transform .15s;
        .HeaderSearchBlock {
            transition: all .15s, transform .15s; }
        .HeaderLogoImage {
            transition: all .15s, transform .15s; } }
    > nav {
        transition: all .15s, transform .15s; } }

#app-wrap.header-size-s {
    > nav {
        top: 60px !important;
        height: calc(100% - #{$main_block_top_padding_s}); }
    .appHeader {
        height: 60px !important;
        .HeaderSearchBlock {
            height: 60px !important; }
        .HeaderLogoImage {
            height: 50px !important;
            width: 29px !important; } } }


@import './ui';
