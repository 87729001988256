@import '../../../sass/settings';

.wrap {
    min-width: 250px;
    border: 2px solid $accent;
    background-color: $main;
    position: absolute;
    bottom: -10px;
    right: 10px;
    transform: translate(0%, 100%);
    @include shadow();
    &::before {
        content: "";
        top: -20px;
        right: 5px;
        border: 10px solid transparent;
        border-bottom: 10px solid $accent;
        position: absolute; } }
