@import '../../sass/settings';

.content {
    @include flex(row, center, center);
    margin-top: $header_height;
    width: 100%;
    .main {
        @include flex(row, center, center);
        width: 100%;
        height: 100%;
        .block {
            @include flex(column, flex-start, center);
            @media screen and ( max-width: 600px ) {
              width: 360px; }
            width: 630px;
            min-height: 700px;
            background: linear-gradient(180deg, #252525 0%, #1F1F1F 100%);
            box-shadow: 0px 20px 14px rgba(0, 0, 0, 0.15);
            border-radius: 12px;
            .logo {
                width: 160px;
                height: 160px;
                margin-top: -110px;
                margin-right: 20px;
                background: url("../../assets/img/registration-logo.png") no-repeat;
                filter: drop-shadow(0px 30px 10px rgba(0, 0, 0, 0.15)); }
            .registrationInfo {
                @include flex(column, center, center);
                .register {
                    font-weight: 600;
                    font-size: 22px;
                    line-height: 185%;
                    margin: 20px 0px;
                    text-transform: uppercase; } }
            .registrationForm {
                @include flex(row, flex-start, flex-start);
                width: 100%;
                flex-wrap: wrap;
                margin-left: 24px;
                @media screen and ( max-width: 600px ) {
                    margin-left: 0px; }
                label {
                    margin-top: 9px;
                    .MuiFormLabel-filled {
                        margin-top: 0; } }
                fieldset {
                    border-color: rgba(255, 255, 255, 0.3);
                    border-radius: 12px; }
                > div {
                    @media screen and ( max-width: 800px ) {
                        flex-basis: 95%; }
                    flex-basis: 45%;
                    margin: 10px; } }
            .terms {
                align-self: flex-start;
                display: flex;
                align-items: center;
                margin-top: 10px;
                margin-left: 12px; }
            .registerBtn {
                width: 340px;
                height: 60px;
                background: $accent;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.2);
                border-radius: 10px;
                margin: 40px;
                span {
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 130%;
                    text-transform: uppercase;
                    color: #FFFFFF; } }
            .links {
                p {
                    margin: 5px 0px;
                    color: rgba(255, 255, 255, 0.3); }
                a {
                    color: #FFFFFF !important; } } } } }
.uploadWrap {
    @include flex(row, space-between, center);
    margin-top: 10px;
    > span {
        font-size: 90%;
        color: #cecece; }
    .uploadBtn {
        width: 70px;
        margin-left: auto !important; } }
