/** Mixins **/
@mixin flex($der, $justy, $align) {
  display: flex;
  flex-direction: $der;
  justify-content: $justy;
  align-items: $align; }

@mixin shadow() {
  box-shadow: -5px 5px 10px 0px rgba(#000, .35); }

@mixin staticHeight($height) {
  min-height: $height;
  max-height: $height;
  height: $height; }

/** Vars (common) **/
$main: #2F2F2F;
$main_background: #191818;
$accent: var(--accent-color);
$accent_r: var(--accent-color-r);
$accent_g: var(--accent-color-g);
$accent_g10: var(--accent-color-g10);
$accent_b: var(--accent-color-b);
$text: #cecece;
$error: #f44336;
$success: rgb(102, 187, 106);
$container_background_color: #1B1B1B;
$container_item_background_color: #212121;
$gutter: 20px;

$main_block_top_padding: 130px;
$main_block_top_padding_s: 70px;
$header_height: 120px;
$sidebar_width: 330px;

$table_row_height: 55px;

/** Vars (MuiAlert) **/
$MuiAlert_main_color: rgb(166, 213, 250);

/** main classes **/
.container {
  padding: $gutter;
  @include flex(row, space-between, flex-start);
  position: relative;
  width: 100%;
  &.wrap {
    flex-wrap: wrap; }
  .box {
    &.col {
      @include flex(column, flex-start, flex-start);
      padding: 0 $gutter /2;
      > div {
        width: 100%; } }
    &.row {
      @include flex(row, flex-start, flex-start);
      width: 100%; } }
  form {
    padding: $gutter;
    @include flex(row, flex-start, flex-start);
    position: relative;
    flex-wrap: wrap;
    width: 100%;
    > .box.col {
      flex-basis: 33.33%;
      @media screen and ( min-width: 2560px ) {
        flex-basis: 0%;
        margin-right: 2em; } } } }

@media screen and ( max-width: 580px ) {
  $gutter: 5px;
  .container, .col, form {
    padding: $gutter; } }
@media screen and ( max-width: 450px ) {
  $gutter: 0px;
  .container, .col, form {
    padding: $gutter; } }

.text-overflow-hidden {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.icon {
  color: $accent; }

.richtext-output-wrap {
  .se-video-container {
    figure {
      padding-bottom: 0 !important; } } }
