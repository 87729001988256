@import '../../sass/settings';

.content {
    @include flex(row, center, center);
    height: 100%;
    width: 100%;
    .main {
        @include flex(row, center, center);
        width: 100%;
        height: 100%;
        .block {
            @include flex(column, flex-start, center);
            width: 410px;
            height: 490px;
            background: linear-gradient(180deg, #252525 0%, #1F1F1F 100%);
            box-shadow: 0px 20px 14px rgba(0, 0, 0, 0.15);
            border-radius: 12px;
            .logo {
                width: 212px;
                height: 168px;
                margin-top: -100px;
                margin-right: 20px;
                background: url("../../assets/img/password-reset-logo.png") no-repeat;
                filter: drop-shadow(0px 30px 10px rgba(0, 0, 0, 0.15)); }
            .forgot {
                font-weight: 600;
                font-size: 22px;
                line-height: 185%;
                text-transform: uppercase;
                color: #FFFFFF;
                margin: 20px 0px; }
            .info {
                font-weight: 400;
                font-size: 16px;
                line-height: 170%;
                text-align: center;
                margin: 0px 30px;
                color: rgba(255, 255, 255, 0.3); }
            .form {
                @include flex(column, center, center);
                width: 100%;
                padding: 0px 30px;
                margin-top: 30px;
                .resetBtn {
                    background: $accent;
                    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.2);
                    border-radius: 10px;
                    height: 55px;
                    margin: 35px 0px;
                    span {
                        font-size: 18px;
                        line-height: 130%;
                        text-transform: uppercase;
                        color: #FFFFFF; } }
                .cancelBtn {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 170%;
                    text-align: center;
                    text-decoration-line: underline;
                    color: #FFFFFF; }
                .response {
                    margin-top: 60px;
                    font-size: 25px; }
                .setPassword {
                    width: 100%;
                    margin-bottom: 50px; } }

            fieldset {
                border-color: rgba(255, 255, 255, 0.3);
                border-radius: 12px; } } } }

